import { FC, Fragment } from 'react';
import { SpinProps, Spin } from 'antd-5';

interface IProps {
  text?: string;
}

export const Loader: FC<IProps & SpinProps> = ({
  text = 'Loading...',
  ...remainingProps
}) => (
  <Spin size="small" tip={text} fullscreen {...remainingProps}>
    {/*  eslint-disable-next-line react/jsx-no-useless-fragment */}
    <Fragment />
  </Spin>
);
