import { Layout as AntdLayout, LayoutProps } from 'antd-5';
import {
  Content as AntdContent,
  Footer as AntdFooter,
  Header as AntdHeader,
} from 'antd-5/es/layout/layout';
import styles from './layout.module.scss';
import clsx from 'clsx';

export const Layout = ({ children, className, ...props }: LayoutProps) => {
  return (
    <AntdLayout className={clsx(styles.Layout, className)} {...props}>
      {children}
    </AntdLayout>
  );
};

export const Header = ({ children, className, ...props }: LayoutProps) => {
  return (
    <AntdHeader className={clsx(styles.Header, className)} {...props}>
      {children}
    </AntdHeader>
  );
};
export const Content = ({ children, className, ...props }: LayoutProps) => {
  return (
    <AntdContent className={clsx(styles.Content, className)} {...props}>
      {children}
    </AntdContent>
  );
};

export const Footer = ({ children, className, ...props }: LayoutProps) => {
  return (
    <AntdFooter className={clsx(styles.Footer, className)} {...props}>
      {children}
    </AntdFooter>
  );
};
