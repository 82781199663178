import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import {
  UseFaceDetectionParams,
  useTimeout,
  useFaceDetection,
} from '@datapeace/1up-frontend-web-utils';
import styles from './face-camera.module.scss';
import { Camera, CameraProps } from '../camera/camera';

export interface FaceCameraProps
  extends Pick<
      CameraProps,
      | 'disabled'
      | 'loading'
      | 'onCapture'
      | 'hideCaptureButton'
      | 'captureAreaBoxSize'
      | 'info'
      | 'videoConstraints'
      | 'videoElementRef'
    >,
    Pick<
      UseFaceDetectionParams,
      | 'detectionMinFaceSize'
      | 'shouldDrawDetection'
      | 'downscaleBy'
      | 'framesMemorySize'
      | 'onDetection'
    >,
    Partial<
      Pick<
        UseFaceDetectionParams,
        'validRangeMinFaceSize' | 'detectionScoreThreshold'
      >
    > {
  autoCapture?: boolean;
  captureAreaOffset?: number;
  className?: string;
  style?: React.CSSProperties;
}

export const FaceCamera: React.FC<FaceCameraProps> = ({
  autoCapture = true,
  detectionMinFaceSize,
  validRangeMinFaceSize = 200,
  detectionScoreThreshold,
  captureAreaOffset = 20,
  framesMemorySize,
  shouldDrawDetection,
  onDetection,
  downscaleBy,
  className,
  videoElementRef,
  ...rest
}) => {
  const triggerCaptureRef = useRef<() => void>(() => null);

  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement | null>(
    null
  );
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  );
  const { faceStatus } = useFaceDetection({
    detectionMinFaceSize,
    validRangeMinFaceSize,
    inputVideo: videoElement,
    outputCanvas: canvasElement,
    detectionScoreThreshold,
    shouldDrawDetection,
    onDetection,
    framesMemorySize,
    downscaleBy,
  });
  useTimeout(
    triggerCaptureRef.current,
    canvasElement && faceStatus === 'YES' && autoCapture ? 300 : null
  );

  return (
    <Camera
      triggerCaptureRef={triggerCaptureRef}
      videoElementRef={(el) => {
        setVideoElement(el);
        if (videoElementRef) {
          videoElementRef(el);
        }
      }}
      className={clsx(
        styles.FaceCamera,
        faceStatus === 'YES' && styles.FaceCameraWithFace,
        className
      )}
      captureAreaBoxSize={validRangeMinFaceSize + captureAreaOffset}
      info={
        <span className={clsx(styles.FaceCameraMessage)}>
          Stand near device to capture!
        </span>
      }
      {...rest}
    >
      {videoElement?.videoWidth && <canvas ref={setCanvasElement} />}
    </Camera>
  );
};
