import { Button, Result, Flex, Image } from 'antd-5';
import { useState } from 'react';
import { ReloadOutlined } from '@ant-design/icons';

export function CookieChecker({
  logoUrl,
  children,
}: {
  children: React.ReactNode;
  logoUrl?: string;
}) {
  const [isCookieEnabled] = useState(navigator.cookieEnabled);
  const handleCheckCookies = () => window && window.location.reload();

  if (!isCookieEnabled) {
    return (
      <Flex vertical align="center" justify="center" style={{ height: '100%' }}>
        {!!logoUrl && (
          <Image
            src={logoUrl}
            alt="logo"
            height="56px"
            style={{ objectFit: 'contain' }}
            preview={false}
          />
        )}
        <Result
          status="error"
          title="Cookies are disabled"
          style={{ margin: 'auto' }}
          subTitle="Your browser has cookies disabled. Make sure your cookies are enabled and try again."
          extra={[
            <Button
              key="Try"
              onClick={handleCheckCookies}
              icon={<ReloadOutlined />}
            >
              Try Again
            </Button>,
          ]}
        ></Result>
      </Flex>
    );
  }
  return children;
}
