import React from 'react';
import { Form as AntdForm, FormItemProps, FormProps } from 'antd-5';

export interface AntdFormProps extends FormProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const Form: React.FC<AntdFormProps> = ({ children, ...rest }) => {
  return <AntdForm {...rest}>{children}</AntdForm>;
};

export interface AntdFormItemProps extends FormItemProps {
  children?: React.ReactNode;
}

export const FormItem: React.FC<AntdFormItemProps> = ({
  children,
  ...rest
}) => {
  return <AntdForm.Item {...rest}>{children}</AntdForm.Item>;
};
