import { useState, useEffect } from 'react';
import {
  ISpace,
  IOrganization,
  IOrganizationConfig,
  IAmsConfig,
  IErrorResponse,
} from '@datapeace/1up-frontend-shared-api';
import {
  getOrganizationConfig,
  getAmsSpaceConfig,
} from '@datapeace/ams-web-utils';

type IConfig = IOrganizationConfig & IAmsConfig & { isMobilePlan: boolean };

export function useGetConfig(
  currentOrganization: IOrganization | null,
  currentSpace: ISpace | null,
  onError: (error: string) => void
): [IConfig | null, (config: IConfig | null) => void] {
  const [config, setConfig] = useState<IConfig | null>(null);

  useEffect(() => {
    // if space is selected/updated fetch config
    const setupConfig = async () => {
      if (!currentOrganization || !currentSpace) {
        setConfig(null);
        return;
      }

      try {
        const amsConfig = await getAmsSpaceConfig(currentSpace.id);
        const organizationConfig = await getOrganizationConfig(
          currentOrganization.id
        );
        setConfig({
          ...organizationConfig,
          ...amsConfig,
          isMobilePlan:
            organizationConfig.activeFeatures.includes('VMS_MOBILE_NUMBER'),
        });
      } catch (err) {
        onError((err as IErrorResponse).message);
      }
    };

    setupConfig();
  }, [currentOrganization, currentSpace, onError]);

  return [config, setConfig];
}
