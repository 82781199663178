import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  HomeOutlined,
  ReloadOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useIsMounted, useCountdown } from '@datapeace/1up-frontend-web-utils';
import { useProcessDataContext } from '@datapeace/ams-web-components';
import { useConfig } from '@datapeace/ams-web-hooks';
import {
  Content,
  Header,
  Layout,
  ICardProps,
  Card,
} from '@datapeace/1up-frontend-web-ui-antd5';
import { Alert, Button, Flex, Image, Spin } from 'antd-5';
import styles from './temperature.module.scss';
import { IErrorResponse } from '@datapeace/1up-frontend-shared-api';

function useRedirectOnError(shouldRedirect: boolean) {
  const { settings, handleTemperatureSubmit } = useProcessDataContext();

  // after set seconds of successful checkin/checkout redirect to home
  const [redirectDelay] = useCountdown(
    settings.alertScreenDelay,
    shouldRedirect ? 1000 : null
  );

  useEffect(() => {
    if (shouldRedirect && redirectDelay === 0) {
      handleTemperatureSubmit();
    }
  }, [shouldRedirect, redirectDelay, handleTemperatureSubmit]);

  return redirectDelay;
}

export function Temperature(props: RouteComponentProps) {
  const {
    settings,
    handleNavigateToHome,
    handleRecordTemperature,
    handleTemperatureSubmit,
  } = useProcessDataContext();

  const isMountedRef = useIsMounted();
  const { portalLogoUrl } = useConfig();
  const [status, setStatus] = useState<ICardProps['type']>('loading');
  const [error, setError] = useState('');

  const shouldAutoSkip = settings.isAutoSubmitEnabled && status === 'danger';
  const redirectDelay = useRedirectOnError(shouldAutoSkip);

  const fetchTemperature = useCallback(async () => {
    setStatus('loading');
    setError('');

    try {
      await handleRecordTemperature();
      if (!isMountedRef.current) return;
      setStatus('success');
    } catch (err) {
      if (!isMountedRef.current) return;
      setError((err as IErrorResponse).message);
      setStatus('danger');
    }
  }, [handleRecordTemperature, isMountedRef]);

  useEffect(() => {
    fetchTemperature();
  }, [fetchTemperature]);

  let options: Pick<ICardProps, 'type' | 'actions' | 'children' | 'icon'>;

  switch (status) {
    case 'success': {
      options = {
        type: 'success',
        actions: [
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={handleNavigateToHome}
            type="default"
          >
            Back
          </Button>,
          <Button
            onClick={handleTemperatureSubmit}
            type="primary"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
          >
            Continue
          </Button>,
        ],
        children: 'Temperature recorded successfully.',
        icon: <CheckCircleOutlined />,
      };
      break;
    }
    case 'danger': {
      options = {
        type: 'danger',
        actions: [
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={handleNavigateToHome}
            type="default"
          >
            Back
          </Button>,
          <Button
            icon={<ReloadOutlined />}
            onClick={fetchTemperature}
            type="primary"
          >
            Retry
          </Button>,
          <Button onClick={handleTemperatureSubmit} type="default">
            Skip
            <ArrowRightOutlined />
          </Button>,
        ],
        children: error || 'Something went wrong! Please try again.',
        icon: <CloseCircleOutlined />,
      };
      break;
    }
    default: {
      options = {
        type: 'loading',
        actions: [],
        children: 'Waiting for temperature...',
        icon: <Spin size="small" />,
      };
    }
  }

  return (
    <Layout>
      <Header>
        <Flex align="center" justify="space-between">
          <Button
            shape="circle"
            onClick={() => handleNavigateToHome()}
            icon={<ArrowLeftOutlined />}
          />
          <Image
            src={portalLogoUrl || '/images/ams_logo.png'}
            className={styles.BrandImage}
            alt="1UPAMS"
            preview={false}
          />
          <Button
            onClick={handleNavigateToHome}
            shape="circle"
            icon={<HomeOutlined />}
          />
        </Flex>
      </Header>
      <Content>
        <Flex align="center" justify="center" vertical>
          <Card
            type={options?.type}
            avatar="/temperature.gif"
            title="Please place your hands in-front of thermometer device"
            actions={options?.actions}
            highlightText
          >
            <Flex align="center" justify="center" gap={4} vertical>
              {options?.icon}
              {options?.children}
            </Flex>
          </Card>
          {shouldAutoSkip && (
            <Alert
              className={styles.RedirectAlert}
              type="warning"
              message={`This screen will close in ${redirectDelay} seconds `}
              banner
            />
          )}
        </Flex>
      </Content>
    </Layout>
  );
}
