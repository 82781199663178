import React, { FC } from 'react';
import {
  IOrganizationDetailed,
  ISpace,
  IOrganizationConfig,
  IAmsConfig,
  getOrganizationSpaces,
  IErrorResponse,
} from '@datapeace/1up-frontend-shared-api';
import { CloseCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { debounce } from '@datapeace/1up-frontend-web-utils';
import { Form, Input, Flex, Button, message } from 'antd-5';
import { redirectToLogout } from '@datapeace/ams-web-utils';
import { useConfigContainer } from '@datapeace/ams-web-hooks';
import {
  Loader,
  Card,
  SelectionPage,
} from '@datapeace/1up-frontend-web-ui-antd5';

// AMS app specific types
export interface IConfigContext extends IOrganizationConfig, IAmsConfig {
  email: string;
  isMobilePlan: boolean;
  organizations: IOrganizationDetailed[] | null;
  currentOrganization: IOrganizationDetailed | null;
  currentSpace: ISpace | null;
  clearCurrentSpace: () => void;
  clearCurrentOrganization: () => void;
}

const PAGE_SIZE = 10;
const defaultSearchFilterValue = {
  searchText: '',
};

export const ConfigProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    email,
    error,
    config,
    isLoading,
    organizations,
    currentOrganization,
    currentSpace,
    handleClearSpace,
    handleClearOrganization,
    Provider,
    handleSelectSpace,
    handleSelectOrganization,
  } = useConfigContainer();
  const [form] = Form.useForm();
  const [isFetchingOrganizationSpace, setIsFetchingOrganizationSpace] =
    React.useState(true);
  const [organizationSpaces, setOrganizationSpaces] = React.useState<ISpace[]>(
    []
  );
  const [apiParams, setApiParams] = React.useState({
    page: 1,
    searchFilterSubmittedValue: defaultSearchFilterValue.searchText,
  });

  const [organizationSpacesNext, setOrganizationSpacesNext] = React.useState<
    string | null
  >(null);

  const fetchOrganizationSpace = React.useCallback(
    async ({
      page = 1,
      searchText = defaultSearchFilterValue.searchText,
      clearPreviousData = false,
    }: {
      page: number;
      searchText: string;
      clearPreviousData: boolean;
    }) => {
      if (organizations && currentOrganization && !currentSpace) {
        setIsFetchingOrganizationSpace(true);
        clearPreviousData && setOrganizationSpaces([]);
        try {
          const { items, next } = await getOrganizationSpaces(
            currentOrganization?.id,
            {
              search: searchText,
              page,
              pageSize: PAGE_SIZE,
              active: 1,
            }
          );
          setOrganizationSpaces((prevState) => [...prevState, ...items]);
          setOrganizationSpacesNext(next);
          setApiParams({
            page,
            searchFilterSubmittedValue: searchText,
          });
        } catch (err) {
          message.error((err as IErrorResponse).message);
        }

        setIsFetchingOrganizationSpace(false);
      }
    },
    [currentOrganization, currentSpace, organizations]
  );

  React.useEffect(() => {
    fetchOrganizationSpace({
      page: 1,
      searchText: '',
      clearPreviousData: true,
    });
  }, [fetchOrganizationSpace]);

  const handleSearchFilterSubmit = (value: string) => {
    fetchOrganizationSpace({
      page: 1,
      searchText: value,
      clearPreviousData: true,
    });
  };

  const handleLoadMoreOrganizationSpace = ({
    page,
    searchText,
  }: {
    page: number;
    searchText: string;
  }) => {
    if (!organizationSpacesNext) {
      return;
    }
    fetchOrganizationSpace({
      page,
      searchText,
      clearPreviousData: false,
    });
  };

  const handleSearchFilterSubmitWithDebounce = debounce(
    (searchText: string) => {
      handleSearchFilterSubmit(searchText);
    },
    400
  );

  if (isLoading) {
    return (
      <Flex align="center" justify="center">
        <Loader />
      </Flex>
    );
  }

  if (!email) {
    throw new Error('Email not found in ConfigProvider');
  }

  if (error) {
    return (
      <Flex align="center" justify="center" style={{ height: '100%' }}>
        <Card
          title={error}
          avatar={<CloseCircleOutlined />}
          type="danger"
          actions={[
            <Button onClick={handleClearOrganization} type="primary">
              Change Organization
            </Button>,
            <Button onClick={redirectToLogout} danger type="default">
              Logout
            </Button>,
          ]}
        >
          Please ask for assistance from the concerned person in order to
          complete the process.
        </Card>
      </Flex>
    );
  }

  if (config) {
    const contextValue: IConfigContext = {
      ...config,
      email,
      organizations,
      currentOrganization,
      currentSpace,
      clearCurrentSpace: handleClearSpace,
      clearCurrentOrganization: handleClearOrganization,
    };
    return <Provider value={contextValue}>{children}</Provider>;
  }

  if (organizations && currentOrganization && !currentSpace) {
    return (
      <Flex align="center" justify="center" style={{ height: '100%' }}>
        <SelectionPage
          key="space"
          title={
            <Flex align="flex-start" justify="center">
              <Form form={form} layout="vertical" style={{ width: '100%' }}>
                <Form.Item
                  name="choose-space"
                  label="Choose Space"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator() {
                        if (
                          getFieldValue('choose-space') !== '' &&
                          getFieldValue('choose-space').length < 3
                        ) {
                          return Promise.reject(
                            new Error(
                              'Please enter at least 3 characters to search.'
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Search
                    allowClear
                    placeholder="Type here to search space"
                    onChange={(event) => {
                      if (
                        event.target.value.length >= 3 ||
                        event.target.value === ''
                      ) {
                        handleSearchFilterSubmitWithDebounce(
                          event.target.value
                        );
                      }
                    }}
                    onSearch={(value, event) => {
                      event && event.preventDefault();
                      if (value !== '' && value.length >= 3) {
                        handleSearchFilterSubmitWithDebounce(value);
                      }
                    }}
                  />
                </Form.Item>
              </Form>
            </Flex>
          }
          onSelect={handleSelectSpace}
          isFetching={isFetchingOrganizationSpace}
          handleLoadMore={() => {
            handleLoadMoreOrganizationSpace({
              page: apiParams.page + 1,
              searchText: apiParams.searchFilterSubmittedValue,
            });
          }}
          hasMoreNextPageItems={organizationSpacesNext}
          options={organizationSpaces}
          onBack={
            organizations.length > 1 ? handleClearOrganization : undefined
          }
          emptyMessage="No spaces found!"
        />
      </Flex>
    );
  }

  if (organizations && !currentOrganization) {
    return (
      <Flex align="center" justify="center" style={{ height: '100%' }}>
        <SelectionPage
          key="org"
          title="Choose Organization"
          onSelect={handleSelectOrganization}
          options={organizations}
          extra={
            <Button onClick={redirectToLogout} danger icon={<LogoutOutlined />}>
              Logout
            </Button>
          }
          emptyMessage="No organizations with AMS plan found!"
        />
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="center">
      <Loader />
    </Flex>
  );
};
