import { useState, useEffect } from 'react';
import {
  IOrganizationDetailed,
  ISubscription,
  IErrorResponse,
} from '@datapeace/1up-frontend-shared-api';
import { getMyOrganizations } from '@datapeace/ams-web-utils';

export function useGetOrganizations(onError: (error: string) => void) {
  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState<
    IOrganizationDetailed[] | null
  >(null);

  useEffect(() => {
    const getOrganizations = async () => {
      setIsLoading(true);
      try {
        const { items: orgs } = await getMyOrganizations();
        const hasActiveSubscriptionOrgs = orgs.filter((org) => {
          return org.activeSubscriptions.some((subscription: ISubscription) =>
            subscription.plan.product.shortName.includes('AMS')
          );
        });
        setOrganizations(hasActiveSubscriptionOrgs);
      } catch (err) {
        onError((err as IErrorResponse).message);
      }
      setIsLoading(false);
    };

    getOrganizations();
  }, [onError]);

  return [organizations, setOrganizations, isLoading] as const;
}
