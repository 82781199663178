import React, { FC } from 'react';
import { Alert } from 'antd-5';
import { useIsOnline } from '@datapeace/1up-frontend-web-utils';

export interface OnlineStatusProps {
  children: React.ReactNode;
}
export const OnlineStatus: FC<OnlineStatusProps> = ({ children }) => {
  const isOnline = useIsOnline();

  return (
    <>
      {!isOnline && (
        <Alert
          banner
          message="No internet connection! Please inform the concerned person about this issue."
          type="error"
          showIcon
        />
      )}
      {children}
    </>
  );
};
