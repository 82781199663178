import React, { FC, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import clsx from 'clsx';
import {
  CameraOutlined,
  UndoOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  getImageFromVideo,
  IBlobWithDataURL,
  objectFit,
  useMeasure,
  useTimeout,
  useIsMounted,
  useCountdown,
} from '@datapeace/1up-frontend-web-utils';
import styles from './camera.module.scss';
import { IErrorResponse } from '@datapeace/1up-frontend-shared-api';
import { FloatButton, Typography, Button, Modal } from 'antd-5';

const errorMessageDurationSeconds = 5;

export interface CameraProps {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  captureAreaBoxSize?: number;
  info?: React.ReactNode;
  hideCaptureButton?: boolean;
  videoConstraints?: MediaTrackConstraints;
  videoElementRef?: (videoElementRef: HTMLVideoElement | null) => void;
  onCapture?: (blob: IBlobWithDataURL | null) => void | Promise<void>;
  // onError?: (errorMessage: string) => void;
  triggerCaptureRef?: React.MutableRefObject<() => Promise<void> | void>;
  children?: React.ReactNode;
}

export const Camera: FC<CameraProps> = ({
  className,
  disabled,
  loading,
  style,
  /** capture area size relative to videoWidth */
  captureAreaBoxSize = 0,
  info,
  hideCaptureButton = false,
  videoConstraints,
  videoElementRef,
  onCapture,
  // onError,
  children,
  triggerCaptureRef,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [videoElement, setVideoElement] = useState<
    (Webcam & HTMLVideoElement) | null
  >(null);

  useTimeout(
    () => setErrorMessage(''),
    errorMessage ? errorMessageDurationSeconds * 1000 : null
  ); // clear error message after some time

  const [containerRef, { width: containerWidth, height: containerHeight }] =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore  (ignore till type issue fixed in library)
    useMeasure<HTMLElement | null>();

  // send videoElement only if in playing state
  useEffect(() => {
    if (!videoElementRef) return;

    if (!videoElement?.video) {
      videoElementRef(null);
      return;
    }

    if (!videoElement.video.paused) {
      videoElementRef(videoElement.video);
      return;
    }

    const handlePlayEvent = () => videoElementRef(videoElement.video);

    videoElement.video.addEventListener('playing', handlePlayEvent);

    // eslint-disable-next-line consistent-return
    return () =>
      videoElement.video?.removeEventListener('playing', handlePlayEvent);
  }, [videoElement, videoElementRef]);

  const isMountedRef = useIsMounted();

  const handleCapture = async () => {
    if (loading || disabled) return;
    if (previewImage) {
      // reset if already captured
      setPreviewImage('');
      if (onCapture) onCapture(null);
      return;
    }

    try {
      setIsLoading(true);

      if (!videoElement?.video) {
        throw new Error('Failed to capture! Please check camera permissions.');
      }

      const blob = await getImageFromVideo(videoElement.video);
      if (!isMountedRef.current) return;

      if (!blob?.size) {
        throw new Error('Failed to capture! Please check camera permissions.');
      }

      setPreviewImage(blob.dataURL);
      if (onCapture) await onCapture(blob);
    } catch (err) {
      if (!isMountedRef.current) return;
      setPreviewImage('');
      setErrorMessage((err as IErrorResponse).message);
    } finally {
      if (isMountedRef.current) setIsLoading(false);
    }
  };

  if (triggerCaptureRef) {
    // eslint-disable-next-line no-param-reassign
    triggerCaptureRef.current = handleCapture;
  }

  // captureAreaBox size is relative to videoWidth, convert it relative to actual element width
  const videoWidth = videoElement?.video?.videoWidth || containerWidth;
  const videoHeight = videoElement?.video?.videoHeight || containerHeight;
  const { width: objectFitWidth } = objectFit(false)(
    containerWidth,
    containerHeight,
    videoWidth,
    videoHeight
  );

  const [transitionDelay, resetTransitionDelay] = useCountdown(1, 200);
  useEffect(() => {
    if (!isLoading) resetTransitionDelay();
  }, [isLoading, resetTransitionDelay]);

  const stretchedCaptureAreaBoxSize =
    captureAreaBoxSize * (objectFitWidth / videoWidth);

  const captureAreaBorderVertical = Math.max(
    0,
    (containerHeight - stretchedCaptureAreaBoxSize) / 2
  );
  const captureAreaBorderHorizontal = Math.max(
    0,
    (containerWidth - stretchedCaptureAreaBoxSize) / 2
  );
  if (errorMessage) {
    return (
      <Modal
        title="Error while capturing face"
        open={true}
        closable={false}
        centered
        footer={[
          <Button
            onClick={() => setErrorMessage('')}
            danger
            icon={<UndoOutlined />}
          >
            Retake photo
          </Button>,
        ]}
      >
        {errorMessage || 'Something went wrong! Please try again.'}
      </Modal>
    );
  }
  const _isDisabled = !(videoElement || previewImage) || disabled;
  const _isLoading = isLoading || loading;
  return (
    <div
      ref={containerRef}
      className={clsx(
        styles.CameraContainer,
        videoConstraints?.facingMode === 'user' && styles.CameraFlipped,
        className
      )}
      style={style}
    >
      {previewImage ? (
        <img src={previewImage} alt="1UPAMS_Punch" />
      ) : (
        <>
          <Webcam
            videoConstraints={videoConstraints}
            audio={false}
            audioConstraints={false}
            ref={(el: (Webcam & HTMLVideoElement) | null) => {
              setVideoElement(el);
            }}
            className={styles.CameraFeed}
            autoPlay
            muted
            playsInline
          />
          {!!captureAreaBoxSize && (
            <div
              className={styles.CameraCaptureArea}
              style={{
                borderWidth: `${captureAreaBorderVertical}px ${captureAreaBorderHorizontal}px`,
              }}
            />
          )}
          {info && (
            <Typography.Title level={4} className={styles.CameraInfo}>
              {info}
            </Typography.Title>
          )}
          {children}
        </>
      )}
      <FloatButton
        icon={
          _isLoading ? (
            <LoadingOutlined />
          ) : previewImage ? (
            <UndoOutlined />
          ) : (
            <CameraOutlined />
          )
        }
        shape="circle"
        onClick={handleCapture}
        className={clsx(
          _isDisabled && styles.CaptureButton__Disabled,
          styles.CaptureButton,
          !!transitionDelay && styles.CaptureButtonInitial,
          hideCaptureButton && styles.CaptureButton__Hidden
        )}
      />
    </div>
  );
};
