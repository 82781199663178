import { RouteComponentProps } from '@reach/router';
import {
  CloseOutlined,
  SyncOutlined,
  ReloadOutlined,
  CheckOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import {
  message,
  Select,
  Radio,
  Button,
  Typography,
  Divider,
  InputNumber,
  Popconfirm,
  Image,
  Flex,
  Dropdown,
  MenuProps,
} from 'antd-5';
import { FREQUENCY } from '@datapeace/1up-frontend-shared-api';
import {
  DistanceCalibrationButton,
  useProcessDataContext,
} from '@datapeace/ams-web-components';
import {
  PROCESS_TYPES,
  StoredSettings,
  AMS_MODES,
  detectionScoreThresholdRange,
  defaultSettings,
} from '@datapeace/ams-web-hooks';
import { onLogout, redirectToLogout } from '@datapeace/ams-web-utils';
import {
  Header,
  Layout,
  Content,
  Card,
  Footer,
  Form,
  FormItem,
} from '@datapeace/1up-frontend-web-ui-antd5';
import styles from './settings.module.scss';
import React from 'react';

const UPGRADE_MESSAGE = 'Please upgrade your plan to access this feature!';

export function Settings(props: RouteComponentProps) {
  const { handleNavigateToHome, settings, updateSettings, config } =
    useProcessDataContext();
  const {
    clearCurrentOrganization,
    organizations,
    clearCurrentSpace,
    currentOrganization,
    currentSpace,
    email,
    temperatureDevices,
    temperatureFrequency,
  } = config;

  const logoUrl = currentOrganization?.logoUrl || '';
  const spaceName = currentSpace?.name || '';
  const features = currentOrganization?.features;

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Logout',
      icon: <LogoutOutlined />,
      danger: true,
      onClick: async () => {
        const hide = message.loading('Logging Out', 0);
        await onLogout();
        hide();
        redirectToLogout();
      },
    },
  ];

  return (
    <Layout>
      <Header className={styles.Layout}>
        <Flex align="center" justify="space-between" wrap gap={4}>
          <Flex align="center" justify="space-between" gap={12}>
            <Image
              src={logoUrl || '/images/ams_logo.png'}
              alt={spaceName}
              preview={false}
              className={styles.Logo}
            />
            <Typography.Title level={4}>Settings</Typography.Title>
          </Flex>
          <Flex align="center" justify="flex-start" gap={8} wrap>
            <Typography.Text
              ellipsis={{ tooltip: email }}
              style={{ maxWidth: 200 }}
            >
              {email}
            </Typography.Text>
            <Dropdown.Button
              menu={{ items }}
              trigger={['click', 'hover']}
              style={{ width: 'auto' }}
              buttonsRender={([leftButton, rightButton]) => [
                <Button
                  onClick={() => window.location.reload()}
                  icon={<ReloadOutlined />}
                  type="default"
                >
                  Refresh
                </Button>,
                React.cloneElement(
                  rightButton as React.ReactElement<HTMLButtonElement, string>
                ),
              ]}
            />
            <Button
              onClick={() => handleNavigateToHome()}
              icon={<CloseOutlined />}
              type="primary"
            >
              Close
            </Button>
          </Flex>
        </Flex>
      </Header>
      <Content>
        <Flex align="center" justify="center">
          <Card>
            <Form
              initialValues={settings}
              fields={Object.entries(settings).map(([key, value]) => ({
                name: key,
                value,
              }))}
              onValuesChange={(_, allValues: StoredSettings) =>
                updateSettings(allValues)
              }
              layout="horizontal"
            >
              {!!organizations && !!currentOrganization && (
                <>
                  <FormItem label="Organization" name="organization">
                    <Flex
                      align="center"
                      justify="flex-end"
                      className={styles.EllipsisForm}
                    >
                      <Typography.Text
                        ellipsis={{ tooltip: currentOrganization?.name }}
                      >
                        {currentOrganization.name}
                      </Typography.Text>
                      {organizations.length > 1 && (
                        <Button
                          type="link"
                          onClick={() => clearCurrentOrganization()}
                        >
                          Change
                        </Button>
                      )}
                    </Flex>
                  </FormItem>
                  {!!currentSpace && (
                    <FormItem label="Space" name="space">
                      <Flex
                        align="center"
                        justify="flex-end"
                        className={styles.EllipsisForm}
                      >
                        <Typography.Text
                          ellipsis={{ tooltip: currentSpace?.name }}
                        >
                          {currentSpace.name}
                        </Typography.Text>
                        {currentOrganization.spaces.length > 1 && (
                          <Button
                            type="link"
                            onClick={() => clearCurrentSpace()}
                          >
                            Change
                          </Button>
                        )}
                      </Flex>
                    </FormItem>
                  )}
                </>
              )}
              <Divider />
              <FormItem name="amsMode" label="AMS mode">
                <Select
                  style={{ textAlign: 'left', maxWidth: 220 }}
                  options={[
                    {
                      value: AMS_MODES.NORMAL,
                      emoji: <CheckOutlined />,
                      label: 'Normal Mode',
                    },
                    {
                      value: AMS_MODES.AUTO_CAPTURE,
                      emoji: <SyncOutlined />,
                      label: 'Auto capture (Face)',
                      title: !features?.spacesAmsAppAutoCaptureMode
                        ? UPGRADE_MESSAGE
                        : '',
                      disabled: !features?.spacesAmsAppAutoCaptureMode,
                    },
                  ]}
                />
              </FormItem>
              <FormItem name="processType" label="Punch Type">
                <Radio.Group
                  options={[
                    { label: 'In', value: PROCESS_TYPES.IN },
                    { label: 'Out', value: PROCESS_TYPES.OUT },
                    { label: 'Both', value: PROCESS_TYPES.BOTH },
                  ]}
                  optionType="button"
                />
              </FormItem>
              <FormItem
                name="alertScreenDelay"
                label="Success/Error screen delay"
              >
                <InputNumber
                  min={0.5}
                  max={5}
                  step={0.5}
                  placeholder={defaultSettings.alertScreenDelay.toString()}
                />
              </FormItem>
              <Divider />
              <FormItem name="cameraFacing" label="Camera facing">
                <Radio.Group
                  options={[
                    { label: 'Front camera', value: 'user' },
                    { label: 'Rear camera', value: 'environment' },
                  ]}
                  optionType="button"
                />
              </FormItem>
              <FormItem
                name="detectionScoreThreshold"
                label="Detection Score Threshold"
              >
                <InputNumber
                  min={detectionScoreThresholdRange.min}
                  max={detectionScoreThresholdRange.max}
                  step={5}
                  placeholder={defaultSettings.detectionScoreThreshold.toString()}
                />
              </FormItem>

              <FormItem label="Calibrate detection distance">
                <FormItem name="minFaceSize" noStyle>
                  <InputNumber
                    min={50}
                    max={500}
                    style={{ marginRight: 10 }}
                    placeholder={defaultSettings.minFaceSize.toString()}
                  />
                </FormItem>
                <DistanceCalibrationButton />
              </FormItem>

              {temperatureFrequency !== FREQUENCY.NEVER &&
                features?.spacesVisitorBodyTemperature && (
                  <>
                    <Divider />
                    <FormItem
                      label="Waiting time for temperature (in sec)"
                      name="temperatureWaitTime"
                    >
                      <InputNumber
                        min={5}
                        placeholder={defaultSettings.temperatureWaitTime.toString()}
                      />
                    </FormItem>
                    <FormItem
                      name="temperatureDeviceId"
                      label="Temperature Device"
                    >
                      <Select
                        disabled={!temperatureDevices?.length}
                        placeholder={
                          temperatureDevices?.length
                            ? 'Select Device'
                            : 'No device found'
                        }
                        style={{ textAlign: 'left', width: 220 }}
                        options={temperatureDevices?.map((device) => ({
                          label: device?.name,
                          value: device?.id,
                          key: `temperatureDevice_${device.id}`,
                        }))}
                      />
                    </FormItem>
                  </>
                )}

              <Divider />
              <FormItem
                name="isSoundEnabled"
                label="Sound"
                extra={!features?.spacesAmsAppPunchSound ? UPGRADE_MESSAGE : ''}
              >
                <Radio.Group
                  options={[
                    { label: 'On', value: true },
                    { label: 'Off', value: false },
                  ]}
                  optionType="button"
                  disabled={!features?.spacesAmsAppPunchSound}
                />
              </FormItem>
              <Popconfirm
                title="Reset all settings to default?"
                onConfirm={() => updateSettings(defaultSettings)}
              >
                <Button>Reset to default</Button>
              </Popconfirm>
            </Form>
          </Card>
        </Flex>
      </Content>
      <Footer className={styles.Layout}>
        <Flex align="center" justify="center">
          <Typography.Text title="Go back">{__VERSION__}</Typography.Text>
        </Flex>
      </Footer>
    </Layout>
  );
}
