import React, { useState, useEffect, FC } from 'react';
import {
  IProfile,
  clearSearchParams,
} from '@datapeace/1up-frontend-shared-api';
import { Button, Flex } from 'antd-5';
import {
  redirectToLogin,
  getTokensFromUrl,
  setTokensInStorage,
  getAccessTokenFromStorage,
  LOGGED_OUT_EVENT,
  getProfile,
  onLogout,
} from '@datapeace/ams-web-utils';
import { AuthContext } from '@datapeace/ams-web-hooks';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Card, Loader } from '@datapeace/1up-frontend-web-ui-antd5';

function LoginAlert() {
  return (
    <Flex align="center" justify="center" style={{ height: '100%' }}>
      <Card
        title="Session Expired!"
        avatar={<CloseCircleOutlined />}
        type="danger"
        actions={[
          <Button onClick={() => redirectToLogin()} type="primary">
            Login Again
          </Button>,
        ]}
      >
        Please ask for assistance from the concerned person in order to complete
        the process.
      </Card>
    </Flex>
  );
}

function RedirectToLogin() {
  useEffect(() => {
    redirectToLogin();
  }, []);

  return null;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [profile, setProfile] = useState<IProfile | null>(null);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  // save token if found in URL
  const { accessToken, refreshToken } = getTokensFromUrl();
  if (accessToken) {
    setTokensInStorage(accessToken, refreshToken);
    clearSearchParams();
  }

  const tokenFromStorage = getAccessTokenFromStorage();

  // handles the logout event
  useEffect(() => {
    const handleLogout = async () => {
      await onLogout();
      setIsLoggedOut(true);
    };

    window.addEventListener(LOGGED_OUT_EVENT, handleLogout);
    return () => window.removeEventListener(LOGGED_OUT_EVENT, handleLogout);
  }, []);

  // fetches profile data if token is set
  useEffect(() => {
    setProfile(null);

    if (!tokenFromStorage) {
      return;
    }

    const fetchProfileData = async () => {
      try {
        const profileData = await getProfile();
        setProfile(profileData);
      } catch (error) {
        console.error(error);
        setIsLoggedOut(true);
      }
    };

    fetchProfileData();
  }, [tokenFromStorage]);

  if (isLoggedOut) {
    return <LoginAlert />;
  }

  if (!tokenFromStorage) {
    return <RedirectToLogin />;
  }

  if (profile) {
    return (
      <AuthContext.Provider value={profile}>{children}</AuthContext.Provider>
    );
  }

  return (
    <Flex align="center" justify="center">
      <Loader />
    </Flex>
  );
};
