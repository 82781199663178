import { useState } from 'react';
import {
  useTimeout,
  useIsWindowFocused,
} from '@datapeace/1up-frontend-web-utils';
import { useProcessDataContext } from '@datapeace/ams-web-components';
import { Button, Flex, Modal } from 'antd-5';
import { FaceCamera, Card } from '@datapeace/1up-frontend-web-ui-antd5';
import { WarningOutlined } from '@ant-design/icons';

interface DistanceCallibratorProps {
  onClose: () => void;
}

function DistanceCallibrator({ onClose }: DistanceCallibratorProps) {
  const { settings, updateSettings } = useProcessDataContext();
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const [faceSizeValue, setFaceSizeValue] = useState(settings.minFaceSize);
  const { detectionScoreThreshold, cameraFacing, detectionDownscaling } =
    settings;

  useTimeout(() => setIsFaceDetected(false), isFaceDetected ? 200 : null);

  const handleCallibrationDone = () => {
    updateSettings({ minFaceSize: faceSizeValue });
    onClose();
  };

  const isWindowFocused = useIsWindowFocused(true);

  return (
    <Modal
      styles={{ body: { padding: 0 } }}
      open
      onOk={handleCallibrationDone}
      okText="Calibrate to this distance"
      onCancel={onClose}
      centered
      closeIcon={false}
      okButtonProps={{ disabled: !isFaceDetected }}
    >
      {isWindowFocused ? (
        <FaceCamera
          videoConstraints={{ facingMode: cameraFacing }}
          onDetection={(status, detection) => {
            setFaceSizeValue(
              Math.round(detection.scale * detectionDownscaling)
            );
            setIsFaceDetected(status === 'YES');
          }}
          hideCaptureButton
          captureAreaOffset={0}
          detectionScoreThreshold={detectionScoreThreshold}
          validRangeMinFaceSize={detectionScoreThreshold}
          captureAreaBoxSize={0}
          autoCapture={false}
          downscaleBy={detectionDownscaling}
        />
      ) : (
        <Flex align="center" justify="center">
          <Card
            title="Tap on screen to see camera view!"
            avatar={<WarningOutlined />}
            type="warning"
          >
            This window is out of focus. Tap here to see the camera view.
          </Card>
        </Flex>
      )}
    </Modal>
  );
}

export function DistanceCalibrationButton() {
  const [isCallibrationWindowOpen, setCallibrationWindowOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setCallibrationWindowOpen(true)}>Calibrate</Button>
      {isCallibrationWindowOpen && (
        <DistanceCallibrator onClose={() => setCallibrationWindowOpen(false)} />
      )}
    </>
  );
}
