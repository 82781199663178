import React from 'react';
import { Card as AntdCard, Avatar, CardProps as AntdCardProps } from 'antd-5';
import clsx from 'clsx';
import styles from './card.module.scss';

export const BasicCard = AntdCard;

export interface ICardProps extends Omit<AntdCardProps, 'type'> {
  hasTopColoredBorder?: boolean;
  /** Either image src or icon */
  avatar?: string | React.ReactNode;
  type?: 'default' | 'success' | 'danger' | 'loading' | 'warning';
  children: React.ReactNode;
  icon?: React.ReactNode;
  highlightText?: boolean;
}

export const Card: React.FC<ICardProps> = ({
  hasTopColoredBorder = true,
  bordered = false,
  avatar,
  type = 'default',
  className,
  children,
  highlightText = false,
  ...props
}) => {
  return (
    <AntdCard
      bordered={bordered}
      className={clsx(
        styles.Card,
        hasTopColoredBorder && styles.CardWithTopBorder,
        avatar && styles.CardWithAvatar,
        highlightText &&
          clsx(styles.Card__Message, styles[`Card-${type}__Message`]),
        styles[`Card-${type}`],
        className
      )}
      styles={{
        body: {
          textAlign: 'center',
        },
        title: {
          whiteSpace: 'normal',
        },
      }}
      {...props}
    >
      {avatar && (
        <Avatar
          className={clsx(
            styles.CardAvatar,
            typeof avatar !== 'string' && styles.CardAvatarWithIcon
          )}
          icon={typeof avatar !== 'string' ? avatar : undefined} // if react node, use avatar as icon
          src={typeof avatar === 'string' ? avatar : undefined} // if string, use avatar as image src
        />
      )}
      {children}
    </AntdCard>
  );
};
const Meta = AntdCard.Meta;
export { Meta };
