import {
  startAccessTokenRefreshProcess,
  logout,
  instance,
  getSearchParamFromUrl,
  redirectWithReturnPath,
  TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  storage,
  IErrorResponse,
} from '@datapeace/1up-frontend-shared-api';
import { captureException } from '@sentry/react';
import { LOGIN_PATH, LOGOUT_PATH } from '@datapeace/ams-web-utils';

export const getTokensFromUrl = () => ({
  accessToken: getSearchParamFromUrl('token') as string,
  refreshToken: getSearchParamFromUrl('refresh-token') as string,
});

export const redirectToLogin = () => redirectWithReturnPath(LOGIN_PATH);
export const redirectToLogout = () => redirectWithReturnPath(LOGOUT_PATH);

export function setTokensInStorage(accessToken: string, refreshToken?: string) {
  storage.setItem(
    TOKEN_STORAGE_KEY,
    accessToken && accessToken.split(' ')?.[0] === 'bearer'
      ? accessToken.substring(accessToken.indexOf(' ') + 1)
      : accessToken
  );

  if (refreshToken) {
    storage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
    startAccessTokenRefreshProcess().catch((err: IErrorResponse) => {
      console.error(err);
      captureException(err);
    });
  }
}

export function getAccessTokenFromStorage() {
  const token = storage.getItem(TOKEN_STORAGE_KEY);
  // trims the text 'bearer ' from the token string, if present
  if (token && token.split(' ')?.[0] === 'bearer') {
    return token.substring(token.indexOf(' ') + 1);
  }

  return token;
}

export async function onLogout() {
  try {
    await logout();
  } catch (e) {
    console.error(e);
  }
  await instance.auth.removeAccessToken();
  await instance.auth.removeRefreshToken();
}
