import { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  CheckCircleOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useProcessDataContext } from '@datapeace/ams-web-components';
import { BRAND_NAME } from '@datapeace/ams-web-utils';
import styles from './welcome.module.scss';
import { Button, Flex, Image, Typography, FloatButton } from 'antd-5';
import { Content, Layout } from '@datapeace/1up-frontend-web-ui-antd5';

export function Welcome(props: RouteComponentProps) {
  const {
    config,
    settings,
    handleResetProcess,
    handleNavigateToSettings,
    handleStartProcess,
  } = useProcessDataContext();

  if (!config) throw new Error('Config not found in Welcome');

  const { portalLogoUrl, portalBackgroundImageUrl, punchButtonLabel } = config;
  const { processType } = settings;

  useEffect(() => {
    handleResetProcess();
  }, [handleResetProcess]);

  return (
    <Layout
      className={styles.Welcome}
      style={{ backgroundImage: `url(${portalBackgroundImageUrl})` }}
    >
      <FloatButton
        type="default"
        className={styles.FloatButton}
        icon={<SettingOutlined />}
        shape="circle"
        onClick={handleNavigateToSettings}
      />
      <Typography.Text className={styles.FloatButton__Text}>
        Powered by&nbsp;{BRAND_NAME}
      </Typography.Text>
      <Content>
        <Flex align="center" justify="center" vertical gap="middle">
          <Image
            src={portalLogoUrl || '/images/ams_logo.png'}
            className={styles.Logo}
            alt="1UPAMS"
            preview={false}
          />

          <Button
            onClick={handleStartProcess}
            type="primary"
            iconPosition="end"
            size="large"
            className={styles.XLargeButton}
            icon={
              processType === 'OUT' ? (
                <LogoutOutlined />
              ) : (
                <CheckCircleOutlined />
              )
            }
          >
            {punchButtonLabel || (
              <>
                {processType === 'IN' && 'Punch In'}
                {processType === 'OUT' && 'Punch Out'}
                {processType === 'BOTH' && 'Punch In/Out'}
              </>
            )}
          </Button>
        </Flex>
      </Content>
    </Layout>
  );
}
