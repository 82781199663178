import React from 'react';
import styles from './selection-page.module.scss';
import { Card } from '../card/card';
import { Button, Empty, Flex, List, Spin } from 'antd-5';
import { ArrowLeftOutlined } from '@ant-design/icons';
import clsx from 'clsx';

type OptionType = { id: string | number; name: React.ReactText };

export interface SelectionPageProps<T extends OptionType = OptionType> {
  title: React.ReactNode;
  options: T[];
  onSelect: (value: T | null) => void;
  onBack?: () => void;
  extra?: React.ReactNode;
  emptyMessage?: string;
  className?: string;
  style?: React.CSSProperties;
  isFetching?: boolean;
  handleLoadMore?: () => void;
  hasMoreNextPageItems?: string | null;
}

export function SelectionPage<T extends OptionType = OptionType>({
  options,
  onSelect,
  emptyMessage = 'No items found!',
  title,
  className,
  extra,
  handleLoadMore,
  hasMoreNextPageItems,
  isFetching,
  style,
  onBack,
}: SelectionPageProps<T>) {
  const renderLoadMoreButton =
    hasMoreNextPageItems && !isFetching ? (
      <Button
        onClick={() => handleLoadMore && handleLoadMore()}
        type="default"
        disabled={isFetching}
        style={{ margin: '12px 0' }}
      >
        Load More...
      </Button>
    ) : null;

  return (
    <Card
      className={clsx(styles.SelectionPage, className)}
      style={style}
      title={title}
      extra={extra}
      actions={
        onBack && [
          <Button icon={<ArrowLeftOutlined />} onClick={onBack}>
            &nbsp;Back
          </Button>,
        ]
      }
    >
      {options?.length > 0 && (
        <List
          className={styles.List}
          itemLayout="vertical"
          loadMore={renderLoadMoreButton}
          dataSource={options || []}
          renderItem={(item, index) => (
            <List.Item
              className={styles.ListItem}
              key={`${item?.id} + ${item?.name} + ${index}`}
              onClick={() => onSelect(item)}
            >
              {item?.name}
            </List.Item>
          )}
        />
      )}
      {options?.length <= 0 && !isFetching && (
        <Empty description={emptyMessage} style={{ margin: '12px 0' }} />
      )}
      {isFetching && <Spin size="small" style={{ margin: '12px 0' }} />}
    </Card>
  );
}
