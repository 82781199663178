/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  MobileOutlined,
  HomeOutlined,
  UndoOutlined,
  ArrowLeftOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useCountdown } from '@datapeace/1up-frontend-web-utils';
import {
  getFullNameOfUser,
  getInitialsFromName,
  getMaskedMobileNumber,
} from '@datapeace/1up-frontend-shared-api';
import { useProcessDataContext } from '@datapeace/ams-web-components';
import { useConfig } from '@datapeace/ams-web-hooks';
import styles from './confirm.module.scss';
import {
  Card,
  Content,
  Header,
  ICardProps,
  Layout,
} from '@datapeace/1up-frontend-web-ui-antd5';
import { Alert, Button, Flex, Image, Spin, Typography } from 'antd-5';

function useRedirectAfterSuccess(isSuccess: boolean) {
  const { settings, handleNavigateToHome } = useProcessDataContext();

  // after set seconds of successful checkin/checkout redirect to home
  const [resendDelay] = useCountdown(
    settings.alertScreenDelay,
    isSuccess ? 1000 : null
  );

  useEffect(() => {
    if (isSuccess && resendDelay === 0) {
      handleNavigateToHome();
    }
  }, [isSuccess, resendDelay, handleNavigateToHome]);

  return resendDelay;
}

export function Confirm(props: RouteComponentProps) {
  const {
    faceData,
    personData,
    handleConfirmSubmit,
    handleNavigateToHome,
    settings,
  } = useProcessDataContext();
  const { portalLogoUrl } = useConfig();
  const [punchType, setPunchType] = useState<'IN' | 'OUT'>('IN');
  const [status, setStatus] = useState<ICardProps['type']>('default');
  const [error, setError] = useState('');

  const shouldAutoSkip = status === 'success' || status === 'danger';

  const resendDelay = useRedirectAfterSuccess(shouldAutoSkip);

  const handleSubmit = useCallback(async () => {
    try {
      setStatus('loading');
      const res = await handleConfirmSubmit();
      setPunchType(res.punchType);
      setStatus('success');
    } catch (err) {
      setStatus('danger');
      setError(
        `${
          (err as any).info
            ? `${(err as any).info.title}${
                (err as any).info.display_text &&
                `: ${(err as any).info.display_text}`
              }`
            : (err as any).message
        }`
      );
    }
  }, [handleConfirmSubmit]);

  useEffect(() => {
    if (settings.isAutoSubmitEnabled) handleSubmit();
  }, [settings.isAutoSubmitEnabled, handleSubmit]);

  let options: Pick<ICardProps, 'type' | 'actions' | 'children' | 'icon'>;

  switch (status) {
    case 'success': {
      options = {
        type: 'success',
        actions: [],
        children: `Punched ${punchType?.toLowerCase()} successfully!`,
        icon: <CheckCircleOutlined />,
      };
      break;
    }
    case 'default': {
      options = {
        type: 'default',
        actions: [
          <Button onClick={handleSubmit} type="primary">
            Punch now
          </Button>,
        ],
        children: 'Click on the button below to record the punch.',
      };
      break;
    }
    case 'danger': {
      options = {
        type: 'danger',
        actions: [
          <Button icon={<UndoOutlined />} onClick={handleSubmit} danger>
            Retry
          </Button>,
        ],
        children: error || 'Something went wrong! Please try again.',
        icon: <CloseCircleOutlined />,
      };
      break;
    }
    default: {
      options = {
        type: 'loading',
        actions: [
          <Button disabled onClick={handleSubmit} type="primary">
            Processing...
          </Button>,
        ],
        children: 'Processing... please wait.',
        icon: <Spin size="small" />,
      };
    }
  }
  return (
    <Layout>
      <Header>
        <Flex align="center" justify="space-between">
          <Button
            shape="circle"
            onClick={() => handleNavigateToHome()}
            icon={<ArrowLeftOutlined />}
          />
          <Image
            src={portalLogoUrl || '/images/ams_logo.png'}
            className={styles.BrandImage}
            alt="1UPAMS"
            preview={false}
          />
          <Button
            onClick={handleNavigateToHome}
            shape="circle"
            icon={<HomeOutlined />}
          />
        </Flex>
      </Header>
      <Content>
        <Flex align="center" justify="center" vertical>
          <Card
            type={options?.type}
            avatar={
              faceData?.dataUrl || (
                <>
                  {getInitialsFromName(
                    getFullNameOfUser({
                      firstName: personData?.firstName,
                      lastName: personData?.lastName,
                    })
                  )}
                </>
              )
            }
            title={
              <Flex vertical align="center" justify="center">
                <Typography.Text
                  ellipsis={{
                    tooltip: getFullNameOfUser({
                      firstName: personData?.firstName,
                      lastName: personData?.lastName,
                    }),
                  }}
                  className={styles.Title}
                >
                  {getFullNameOfUser({
                    firstName: personData?.firstName,
                    lastName: personData?.lastName,
                  })}
                </Typography.Text>
                {personData?.mobileNumber && (
                  <Flex align="center">
                    <MobileOutlined />
                    <Typography.Text>
                      {getMaskedMobileNumber(personData?.mobileNumber)}
                    </Typography.Text>
                  </Flex>
                )}
              </Flex>
            }
            actions={options?.actions}
            highlightText
          >
            <Flex align="center" justify="center" gap={4} vertical>
              {options?.icon}
              {options?.children}
            </Flex>
          </Card>

          {shouldAutoSkip && (
            <Alert
              className={styles.RedirectAlert}
              type={status === 'danger' ? 'error' : 'success'}
              message={
                <Flex justify="space-between" align="center">
                  {`This screen will close in ${resendDelay} seconds `}
                  <Button onClick={handleNavigateToHome} type="dashed">
                    Close now
                  </Button>
                </Flex>
              }
              banner
            />
          )}
        </Flex>
      </Content>
    </Layout>
  );
}
