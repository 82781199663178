import { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  ArrowLeftOutlined,
  SettingOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import {
  IBlobWithDataURL,
  useCountdown,
  useIsWindowFocused,
  useIsMounted,
} from '@datapeace/1up-frontend-web-utils';
import { useProcessDataContext } from '@datapeace/ams-web-components';
import { getFirstScreen } from '../index';
import styles from './face-capture.module.scss';
import {
  Card,
  Content,
  FaceCamera,
  Header,
  Layout,
} from '@datapeace/1up-frontend-web-ui-antd5';
import { Flex, FloatButton, Spin, Typography } from 'antd-5';

export function FaceCapture(props: RouteComponentProps) {
  const {
    settings,
    handleFaceCaptureSubmit,
    handleNavigateToHome,
    handleNavigateToSettings,
  } = useProcessDataContext();
  const {
    detectionScoreThreshold,
    minFaceSize,
    amsMode,
    isAutoSubmitEnabled,
    cameraFacing,
    detectionDownscaling,
  } = settings;

  const isMountedRef = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);

  const isFirstScreen = getFirstScreen(amsMode) === '/face-capture';

  const handleCapture = async (faceData: IBlobWithDataURL | null) => {
    if (isLoading) return;
    try {
      if (!faceData) throw new Error('Failed to capture!');
      setIsLoading(true);
      await handleFaceCaptureSubmit(faceData);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      if (isMountedRef.current) setIsLoading(false);
    }
  };

  const isWindowFocused = useIsWindowFocused(true);

  const [transitionDelay, resetTransitionDelay] = useCountdown(1, 200);
  useEffect(() => {
    if (!isLoading) resetTransitionDelay();
  }, [isLoading, resetTransitionDelay]);

  return (
    <Layout>
      <Header
        className={clsx(
          styles.Header,
          !!transitionDelay && styles.HeaderInitial
        )}
      >
        <Flex
          align="center"
          justify="center"
          vertical
          className={styles.HintText}
          gap={4}
        >
          <UserOutlined style={{ fontSize: 26, fontWeight: 600 }} />
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            {isLoading ? <Spin size="small" /> : 'Take Photo'}
          </Typography.Title>
        </Flex>
      </Header>
      <Content className={styles.Content}>
        {isWindowFocused ? (
          <FaceCamera
            videoConstraints={{ facingMode: cameraFacing || 'user' }}
            autoCapture={isAutoSubmitEnabled}
            validRangeMinFaceSize={minFaceSize}
            detectionScoreThreshold={detectionScoreThreshold}
            captureAreaBoxSize={0}
            loading={isLoading}
            onCapture={handleCapture}
            downscaleBy={detectionDownscaling}
          />
        ) : (
          <Flex align="center" justify="center">
            <Card
              title="Tap on screen to see camera view!"
              avatar={<WarningOutlined />}
              type="warning"
            >
              This window is out of focus. Tap here to see the camera view.
            </Card>
          </Flex>
        )}
      </Content>

      {!isFirstScreen && (
        <FloatButton
          onClick={handleNavigateToHome}
          className={clsx(
            styles.FloatButton,
            !!transitionDelay && styles.FloatButtonInitial
          )}
          icon={<ArrowLeftOutlined />}
          shape="circle"
        />
      )}

      {isFirstScreen && (
        <FloatButton
          className={clsx(
            styles.FloatButton,
            !!transitionDelay && styles.FloatButtonInitial
          )}
          icon={<SettingOutlined />}
          shape="circle"
          onClick={handleNavigateToSettings}
        />
      )}
    </Layout>
  );
}
